<template>
  <div class="main-content">
    <!-- Page head tile -->
    <div class="page-head-tile">
      <div class="container">
        <div class="page-title">
          <h1 class="title-16"><strong>Контакты</strong></h1>
        </div>
      </div>
    </div>
    <!-- Page head tile END -->
    <div class="section">
      <div class="row">
        <div class="content">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div class="post-main-img" v-if="$store.state.about && $store.state.about.imgSchool">
                    <img :alt="$store.state.about.nameSchool" :src="$store.state.about.media_server_url + $store.state.about.imgSchool" class="adaptive" style="height:438px;width:100%;object-fit:contain"/>
                  </div>
                  <div class="post-main-img" v-else>
                    <img class="adaptive" :src="$store.state.system_data.base_url + '/img/placeholder.png'" style="height:438px;width:100%;object-fit:contain"/>
                  </div>
                  <div v-html="$store.state.about.aboutSchool"></div>
                </article>
                <div class="magazine-details" v-if="$store.state.about">
                  <div class="details-info">
                    <div class="address"><i class="li_location"></i>{{ $store.state.about.addressSchool }}</div>
                    <div class="phone"><i class="li_phone"></i> Телефон главы: {{ $store.state.about.phoneDirector }}</div>
                    <div class="email"><i class="li_mail"></i> Email: <a :href="'mailto:' + $store.state.about.mailDirector">{{ $store.state.about.mailDirector }}</a></div>
                    <div class="social-tp-2">
                      <ul>
                        <li class="vk" v-if="$store.state.socials.vk">
                          <a :href="$store.state.socials.vk">
                            <i class="fa fa-vk"></i>
                          </a>
                        </li>
                        <li class="fb" v-if="$store.state.socials.fb">
                          <a :href="$store.state.socials.fb">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li class="in" v-if="$store.state.socials.im">
                          <a :href="$store.state.socials.im">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <aside class="side-bar sticky-wrap">
          <div class="map-widget">
            <div class="pst-block">
              <div class="pst-block-head">
                <h2 class="title-4"><strong>Наше</strong> расположение</h2>
              </div>
              <div class="pst-block-main" id="map" style="height: 300px;">
                <!--                <yandex-map :coords="coords">-->
                <!--                  <ymap-marker-->
                <!--                    :coords="coords"-->
                <!--                    :icon="markerIcon"-->
                <!--                    marker-id="123"/>-->
                <!--                </yandex-map>-->
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contacts',
  async asyncData (context) {
    await context.store.dispatch('contactsPage');
  },
  metaInfo () {
    return this.$seo('Контакты');
  },
  mounted () {
    // Яндекс карты
    ymaps.ready(init);
    let $this = this;
    let myMap, myPlacemark;

    function init () {
      let myGeocoder = ymaps.geocode($this.$store.state.about.addressSchool);
      let coords;
      myGeocoder.then(
        function (res) {
          coords = res.geoObjects.get(0).geometry.getCoordinates();
          myMap = new ymaps.Map('map', {
            center: coords,
            zoom: $this.$store.state.school.category_id === 9 ? 17 : 13
          });
          if ($this.$store.state.school.category_id === 9) {
            myPlacemark = new ymaps.Placemark(coords, {
              hintContent: 'Мы здесь',
              balloonContent: $this.$store.state.about.addressSchool
            });
          }
          myMap.geoObjects.add(myPlacemark);
        });
    }
  }
};
</script>
